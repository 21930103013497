import React, { useEffect, useState } from "react";
import { AnimatePresence } from 'framer-motion'
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { Box } from "@chakra-ui/react";

type CursorLargeProps = {
	x: number,
	y: number,
	useCustomCursor: boolean,
}

export const CursorLarge = (props: CursorLargeProps) => {
	const [isVisible, setIsVisible] = useState(props.useCustomCursor);

	const [mousePosition, setMousePosition] = useState({
		x: props.x,
		y: props.y,
	})

	const mouseMove = (e: MouseEvent) => {
		setMousePosition({
			x: e.clientX,
			y: e.clientY
		})
	}

	const cursorVariants = {
		initial: {
			opacity: 0,
			scale: 0,
		},
		follow: {
			opacity: .1,
			scale: 1,
			left: mousePosition.x - 64,
			top: mousePosition.y - 64,
		},
		exit: {
			opacity: 0,
			scale: 0,
			left: mousePosition.x - 64,
			top: mousePosition.y - 64,
		}
	}

	useEffect(() => {
		window.addEventListener("mousemove", mouseMove);

		return () => {
			window.removeEventListener("mousemove", mouseMove)
		}
	}, []);

	useEffect(() => {
		setIsVisible(props.useCustomCursor)
	}, [props]);

	return (
		<AnimatePresence>
			{isVisible && (
				<MotionBox
					h="128px" w="128px"
					position="fixed"
					zIndex="2"
					pointerEvents="none"
					left={props.x - 64}
					top={props.y - 64}
					initial={cursorVariants.initial}
					animate={cursorVariants.follow}
					exit={cursorVariants.exit}
					transition={{ ease: "easeOut" }}
				>
					<Box w="100%" h="100%" bg="primary.white" borderRadius="4rem" />
				</MotionBox>
			)}
		</AnimatePresence>
	)
}