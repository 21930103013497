import React, { FC, useContext } from "react";
import { Box, StyleProps, Heading, Text as ChakraText } from "@chakra-ui/react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { CaptionBase } from "../generated-types";
import { LinkVariant } from "~/foundation/Theme/components/Link";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type CaptionFooterProps = CaptionBase & {
	variant?: LinkVariant,
	customStyling?: StyleProps
};

export const CaptionFooter: FC<CaptionFooterProps> = ({
	captionTitle,
	captionText,
	captionLink,
	variant = "primary",
	customStyling = {
		maxW: ['none', null, '582'],
		mx: ['4', null, '8', null, '16', null],
		my: ['4', null, '8']
	}
}) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);

	return (
		<Box {...customStyling} color={theme.colors.ribbon.color}>
			<figcaption>
				{(captionTitle?.value || sitecoreContext.pageEditing) &&
					<Heading as="h3" size="xs" mt="4">
						<sc.Text field={captionTitle}/>
					</Heading>
				}
				{(captionText?.value || sitecoreContext.pageEditing) &&
					<ChakraText variant="footerBody" mt="4">
						<sc.Text field={captionText} />
					</ChakraText>
				}
				{(captionLink?.value.href || sitecoreContext.pageEditing) &&
					<Box mt="2" sx={{ "a": { alignItems: "start" } }}>
						<sc.Link
							field={captionLink}
							variant={variant}
							size="md"
							color={theme.colors.ribbon.linkColor} />
					</Box>
				}
			</figcaption>
		</Box>
	);
};