export const ImageCarouselStyling = {
	"sx": {
		".splide__track": {
			overflow: "visible",
		},
		".splide__slide": {
			display: "flex",
			flexDirection: "column",
		},
		".splide__slide__container": {
			maxW: "100%",
			bgColor: "primary.aramcoGrey",
			borderRadius: "lg",
			borderBottomRightRadius: "0",
			display: "flex",
			alignSelf: "end",
			alignItems: "center",
			justifyContent: "center",
			color: "white",
			userSelect: "none",
			'img': {
				w: "100%",
				h: "100%",
				objectFit: "cover",
				objectPosition: "center center",
				borderRadius: "lg",
				borderBottomRightRadius: "0",
			}
		},
		".progressbar__animation": {
			transitionDuration: ".5s",
			transitionProperty: "width",
			transitionTimingFunction: "aramcoCubic"
		}
	}
}